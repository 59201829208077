<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <h3 class="card-title">Profile Resmi Güncelle</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <croppa
              v-model="myCroppa"
              :width="250"
              :height="250"
              :placeholder="'Resim Seçin 500x500'"
              :placeholder-font-size="15"
              :accept="'image/*'"
              :file-size-limit="0"
              :quality="2"
              :zoom-speed="3"
              :disabled="false"
              :disable-drag-and-drop="false"
              :disable-click-to-choose="false"
              :disable-drag-to-move="false"
              :disable-scroll-to-zoom="false"
              :disable-rotation="false"
              :prevent-white-space="false"
              :reverse-scroll-to-zoom="false"
              :show-remove-button="true"
              :remove-button-color="'#c58aa6'"
              :remove-button-size="20"
              canvas-color="transparent"
            ></croppa>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button
          type="button"
          @click="fileUpload"
          id="saveButton"
          class="btn btn-primary"
        >
          Resmi Yükle
        </button>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  created() {
    document.title = "Profil Resmi Güncelle";
  },
  components:{},
  data() {
    return {
      myCroppa: null,
      imgDataUrl: {},
      cropper: {},
      res: "",
    };
  },
  methods: {
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    fileUpload() {
      document.getElementById("saveButton").innerText = "Dosya Yükleniyor...";
      document.getElementById("saveButton").disabled = true;
      let base64img = this.myCroppa.generateDataUrl();
      let file = this.dataURLtoFile(
        base64img,
        this.$store.state.shortlink + ".jpg"
      );
      let formData = new FormData();
      formData.append("file", file);
      this.$store.dispatch("mediaUpload", formData).then((response) => {
        this.$vToastify.success(
          "Resim başarılı bir şekilde yüklendi",
          "Başarılı!"
        );
        this.$store.state.profile_image = response.profile_image;
        document.getElementById("saveButton").innerText = "Resmi Yükle";
        document.getElementById("saveButton").disabled = false;
      });
    },
  },
};
</script>